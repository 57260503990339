/* publisher-app-section */

.publisher-app-section {
  margin: 0 auto;
  padding: 0 160px;
  max-width: 1440px;
}

.publisher-app-section .container {
  padding-left: 70px;
  padding-right: 70px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: #ffffff;
  background: linear-gradient(265.74deg, #1179bf -0.24%, #012d6c 102.38%);
  border-radius: 32px;
}

.publisher-app-section p {
  margin: 0 0 24px;
  font-size: 18px;
  line-height: 24px;
  font-weight: 400;
}

.publisher-app-section h2 {
  margin: 0 0 50px;
  font-size: 32px;
  line-height: 39px;
  font-weight: 700;
}

@media (max-width: 1439px) {
  .publisher-app-section {
    padding: 0 11.11vw;
  }

  .publisher-app-section .container {
    padding-right: 4.85vw;
  }
}

@media (max-width: 767px) {
  .publisher-app-section {
    padding: 0 24px;
  }
}

.publisher-app-section .container .right-side-wrapper {
  position: relative;
  top: 0;
  left: 0;
}

@media (max-width: 1439px) {
  .publisher-app-section .container .right-side-wrapper {
    left: calc((1440px - 100vw) / 5);
  }
}

.publisher-app-section .container .right-side {
  position: relative;
  margin-top: -111px;
  top: 5px;
  left: 0;
  width: 400px;
  padding-right: 100px;
}

.publisher-app-section .container .frame-top {
  z-index: 3;
  position: absolute;
  width: 150px;
  left: 19%;
  top: -93px;
}

.publisher-app-section .container .dashboard-wrapper {
  position: absolute;
  top: -92px;
  left: 20px;
  height: 432px;
  overflow-y: scroll;
  z-index: 2;
  border-radius: 30px 30px 0 0;
  background-color: #fafafa;
}

.publisher-app-section .container .dashboard-wrapper::-webkit-scrollbar {
  background-color: #f5f5f5;
  width: 0 !important;
}

.publisher-app-section .container .dashboard-wrapper::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 6px rgba(255, 255, 255, 0.3);
}

.publisher-app-section .container .dashboard-wrapper::-webkit-scrollbar-thumb {
  background-color: rgba(255, 255, 255, 0.3);
  -webkit-box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.6);
  border-radius: 6px;
}

.publisher-app-section .container .dashboard-pub {
  width: 261px;
}

.publisher-app-section .container .left-side {
  padding-right: 4.65vw;
}

.publisher-app-downloads {
  display: flex;
}

@media (max-width: 959px) {
  .publisher-app-section .container {
    padding: 50px 42px 0 42px;
    flex-direction: column;
  }

  .publisher-app-section .container .left-side {
    margin: 0 auto;
    padding: 0;
    text-align: center;
  }

  .publisher-app-section .container .left-side p {
    font-size: 20px;
    line-height: 22px;
    color: #ffffff;
    margin: auto;
    margin-top: 2rem;
  }

  .publisher-app-section .container .left-side p:not(:first-of-type) {
    font-size: 26px;
    line-height: 1;
  }

  .publisher-app-section .container .right-side-wrapper {
    display: none;
  }

  .publisher-app-section .container .frame-top {
    top: 3.8%;
    left: 24.2%;
    width: 52%;
  }

  .publisher-app-section h2 {
    max-width: 300px;
    margin-top: 24px;
    font-size: 32px;
    line-height: 39px;
  }

  .publisher-app-downloads {
    display: flex;
    flex-direction: column;
    margin-top: 65px;
    margin-bottom: 60px;
  }

  .publisher-app-downloads .publisher-app-download {
    display: block;
    width: 100%;
    max-width: 222px;
    margin: 0 auto;
  }

  .publisher-app-downloads .publisher-app-download:nth-child(1) {
    margin-bottom: 24px;
  }

  .publisher-app-download img {
    width: 100%;
    height: auto;
  }

  .publisher-app-downloads .publisher-app-download:nth-child(2) {
    transform: scaleY(0.9);
  }
}
